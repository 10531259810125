@import 'unisporkal-styles/stylesheets/unisporkal_engine/getty/header/_variables';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_animation';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_colors';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_fonts';
@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_z_index';
@import 'unisporkal-styles/stylesheets/unisporkal/shared/config/_functions';

// IMPORTANT: Please only use pixels for font-size until legacy styles are
// removed from boards and pnp. Otherwise, text on those pages is too small.
$thin-header-height: 74px;
$banner-border-lighten: #7f55e3;
$getty-link-hover-color: $purple;
$getty-minimal-border-color: $grey;
$getty-header-font-size: 14px;
$getty-header-font-size-mobile: 18px;
$primary-font-family: 'Lato', sans-serif;
$panel-width-desktop: 350px;
$nav-link-padding-x: 16px;
$nav-link-padding-x-mobile: 8px;
$nav-bar-padding-y: 4px;
$getty-meganav-column-width: 320px;
$sidebar-meganav-tablet-width: 320px;
$sidebar-meganav-mobile-width: 100vw;
$sidebar-meganav-slide-duration: 400ms;

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  height: unset;
}
