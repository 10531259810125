@import '../Header.config';

.container {
  justify-content: space-between;
  padding: 40px 0;

  .list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    gap: 4px;
  }

  .columnHeader {
    color: #0c0d0d;
    font-size: 1.2em;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 0;
  }

  .separator {
    border: 0.25px solid #ccd3d3;
    margin: 15px 0 20px;
  }
}
