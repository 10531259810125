@import '../Header.config';

@media screen and (prefers-reduced-motion: reduce) {
  .closeMenu {
    width: $panel-width;
    background-color: $istock-mobile-bg-color;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-$panel-width);
    transition: none;
    transition-delay: 0s;

    @media #{$tablet} {
      width: $narrow-panel-width;
      transform: translateX(-$narrow-panel-width);
    }
  }
}

.closeMenu {
  width: $panel-width;
  background-color: $istock-mobile-bg-color;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-$panel-width);
  transition: transform 0.1s;
  transition-delay: 0s;

  @media #{$tablet} {
    width: $narrow-panel-width;
    transform: translateX(-$narrow-panel-width);
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .openMenu {
    width: $panel-width;
    background-color: $istock-mobile-bg-color;
    position: absolute;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-$panel-width);
    transition: none;
    transition-delay: 0s;

    @media #{$tablet} {
      width: $narrow-panel-width;
      transform: translateX(-$narrow-panel-width);
    }
  }
}

.openMenu {
  width: $panel-width;
  background-color: $istock-mobile-bg-color;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-$panel-width);
  transition: transform 0.1s;
  transition-delay: 0s;

  @media #{$tablet} {
    width: $narrow-panel-width;
    transform: translateX(-$narrow-panel-width);
  }
}

.container {
  position: relative;
  height: 100%;
  margin: 0;
}

.menuItem {
  height: $thin-header-height;
  border-bottom: 1px solid #666e6e;
  display: flex;
  align-items: center;
  padding: 0 30px 0 40px;

  a {
    font-size: 14px;
    display: block;
    color: $istock-text-color;
    font-weight: 400;
    width: 100%;
  }

  &:first-child {
    border-right: 1px solid #666e6e;
  }

  @media #{$medium-large-nav} {
    height: $wide-header-height;
  }

  &:hover > a:first-child,
  &:focus > a:first-child {
    color: $istock-link-hover-color;

    @media (hover: none) {
      color: $istock-text-color;
    }
  }

  &:active > a:first-child {
    color: $istock-link-hover-color;
  }

  &:hover > button,
  &:focus > button {
    color: $istock-link-hover-color;

    @media (hover: none) {
      color: $istock-text-color;
    }
  }

  &:active > button {
    color: $istock-link-hover-color;
  }
}
