@import '../Header.config';

$wide-header-height: 85px;
$thin-header-height: 65px;

.container {
  line-height: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $thin-header-height;
  width: auto;
  max-width: none;

  @media #{$large-up-nav-only} {
    height: $wide-header-height;
  }
}

.desktop {
  display: none;

  @media #{$large-up-nav-only} {
    display: block;
  }
}

.mobile {
  display: block;

  @media #{$large-up-nav-only} {
    display: none;
  }
}
