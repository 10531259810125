@import '../Header.config';

.container {
  position: relative;

  @media #{$mobile-lower} {
    display: none;
    visibility: hidden;
  }

  .link {
    padding: 0 3px 0 12px;

    @media #{$mobile-upper} {
      display: none;
    }
  }

  .dropdown {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    max-width: 320px;
    min-width: 170px;
    transition-duration: 0.15s;
    transition-property: all;
    transition-timing-function: ease;
    z-index: 999;
  }

  &:hover,
  &:focus {
    cursor: pointer;

    .link {
      color: $istock-link-hover-color;
    }

    .dropdown {
      visibility: visible;
      opacity: 1;
      transition-duration: 0.15s;
      transition-property: all;
      transition-timing-function: ease;
    }
  }
}
