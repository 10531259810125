@import '../Header.config';

.container {
  display: flex;

  .link {
    border: 0;
    color: $istock-text-color;
    display: inline-flex;
    padding: 0;
    align-items: center;

    .linkIcon {
      margin-right: 0;
      margin-left: 12px;
      width: 25px;

      // stylelint-disable-next-line max-nesting-depth
      path {
        fill: $istock-text-color;
      }
    }
  }

  .caretContainer {
    position: relative;
    transform-style: preserve-3d;
    top: 3px;
    margin: 0 5px;

    .caret {
      display: inline-block !important;
      position: relative;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background: rgb(0 0 0 / 0%);
      box-sizing: content-box;

      @media screen and (prefers-reduced-motion: reduce) {
        // stylelint-disable-next-line max-nesting-depth
        &::before {
          transition: none;
          position: absolute;
          content: '';
          margin: auto;
          width: calc(22px / 3.5);
          height: calc(22px / 3.5);
          background: rgb(0 0 0 / 0%);
          border: calc(22px / 10) solid #fff;
          border-right: none;
          border-top: none;
          top: 0;
          right: 0;
          bottom: calc(30px / 8);
          left: 0;
          transform: rotate(-45deg);
          box-sizing: content-box;
        }
      }

      // stylelint-disable-next-line max-nesting-depth
      &::before {
        transition: border-color 0s cubic-bezier(0.25, 0.1, 0.25, 1);
        position: absolute;
        content: '';
        margin: auto;
        width: calc(22px / 3.5);
        height: calc(22px / 3.5);
        background: rgb(0 0 0 / 0%);
        border: calc(22px / 10) solid #fff;
        border-right: none;
        border-top: none;
        top: 0;
        right: 0;
        bottom: calc(30px / 8);
        left: 0;
        transform: rotate(-45deg);
        box-sizing: content-box;
      }
    }

    @media #{$mobile-upper} {
      padding-left: 5px;
    }
  }

  .dropdown {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 53px;
    width: 265px;

    // Copied from engine, still will work to improve
    transition-duration: 0.15s;
    transition-property: all;
    transition-timing-function: ease;
    z-index: 999;
  }

  .aiGeneratorText {
    margin-left: 0.2em;
    padding-top: 0.01em;
    white-space: nowrap;
  }

  &:active:not([disabled]),
  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    cursor: pointer;
    color: $istock-link-hover-color;
    text-decoration: none;
    transition-duration: 0.3s;

    .link {
      color: $istock-link-hover-color;
    }

    .dropdown {
      visibility: visible;
      opacity: 1;
      transition-duration: 0.15s;
      transition-property: all;
      transition-timing-function: ease;
    }

    .caretContainer {
      top: 7px;

      .caret {
        // stylelint-disable-next-line max-nesting-depth
        &::before {
          border-color: $istock-link-hover-color;
          transform: rotate(135deg);
        }
      }
    }

    .linkIcon {
      path {
        fill: $istock-link-hover-color;
        transition-duration: 0.3s;
      }
    }
  }
}
