/* stylelint-disable no-descending-specificity */
@import '../Header.config';

$notification-font-size: 0.875rem;
$notification-narrow-font-size: rem(12);

.horizontalDivider {
  border: 1px solid #ccd3d3;
  border-bottom-color: #fff;
  display: block;
  margin: 15px 0 25px;
}

.promo {
  display: flex;
  flex: 0 1 auto;

  figure.icon {
    background-image: url('~unisporkal-styles/stylesheets/unisporkal_engine/images/dd_account_panel_bell.svg');
    background-repeat: no-repeat;
    width: 23px;
    flex-shrink: 0;
    margin-right: 10px;
  }

  p {
    flex: 1;
    font-size: $notification-font-size;
    margin: 0 0 11px;
    line-height: 1.3;
    color: #0c0d0d !important;

    a {
      color: $href_color !important;

      &:hover,
      &:focus,
      &:active {
        color: $href_hover_color !important;
      }

      // stylelint-disable-next-line max-nesting-depth, a11y/content-property-no-static-value
      &::after {
        content: ' >';
      }
    }
  }

  .unlimitedText {
    a {
      display: block;
      margin-top: 5px;
    }
  }
}
