@import '../Header.config';

.listItem {
  margin-bottom: 27px;

  a,
  button {
    color: $istock-text-dark-grey !important;

    &:hover,
    &:focus {
      color: $istock-link-hover-color !important;
    }
  }
}
