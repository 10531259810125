@use '@unisporkal/alliance-ui-theme/dist/styles/common' as commonStyles;
@import '../Header.config';

$items-line-height: 31px;
$medium-small: 1200px;
$medium-small-nav: 'screen and (max-width: #{$medium-small})' !default;
$medium-screen: 1368px;
$medium-screen-nav: 'screen and (max-width: #{$medium-screen})' !default;

.container {
  display: flex;
  flex-direction: column;
  background-color: $istock-white;
  border: 1px solid $istock-border-color;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgb(0 0 0 / 32%);
  margin-right: 0;
  margin-top: 15px;
  line-height: 1.4;
  list-style: none;
}

.dropdown {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  width: 260px;
  right: 0;
  left: 100%;
  top: -15px;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: ease;
  z-index: 999;
  padding: 8px 0;
}

.linkList {
  margin-bottom: 0;
  position: relative;
}

.listItem {
  display: flex;
  padding-bottom: 5px;

  &:active:not([disabled]),
  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    > .dropdown {
      visibility: visible;
      opacity: 1;
    }
  }

  a {
    margin: 8px 0;
  }
}

.link {
  padding: 0;
  color: commonStyles.$true-black !important;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 16px;
}

.caret {
  margin-left: auto;
  margin-bottom: 8px;
  margin-top: 8px;
}

.isLastDropdown {
  display: flex;
  flex-wrap: wrap;

  @media #{$medium-small-nav} {
    left: 0;
    transform: translate(-100%);
  }
}

.isPopularCategories {
  max-width: 520px; // this width works for all languages and ensures text is wrapped properly to prevent dropdown from being too wide/cut off.
  width: max-content;
  text-align: center;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 10px 0;
  position: absolute;

  ul {
    padding: 8px 20px !important;
    text-align: left;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media #{$medium-screen-nav} {
    left: 0;
    transform: translate(-100%);
  }
}

.modifyMaxWidth .isPopularCategories {
  max-width: 375px;
}
