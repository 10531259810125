.searchIcon {
  cursor: pointer;
  width: 21px;
  height: 21px;
}

.divider {
  border-right: 1px solid #666e6e;
  height: 24px;
  margin: 0 12px -8px;
  padding: 0;
}
