@import '../Header.config';

.container {
  margin-top: 30px;
  padding: 0 0 30px;

  .caretContainer {
    position: relative;
    transform-style: preserve-3d;
    top: 3px;
    margin-right: 5px;

    .caret {
      display: inline-block !important;
      position: relative;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background: rgb(0 0 0 / 0%);
      box-sizing: content-box;

      @media screen and (prefers-reduced-motion: reduce) {
        // stylelint-disable-next-line max-nesting-depth
        &::before {
          transition: none;
          position: absolute;
          content: '';
          margin: auto;
          width: calc(22px / 3.5);
          height: calc(22px / 3.5);
          background: rgb(0 0 0 / 0%);
          border: calc(22px / 10) solid #fff;
          border-right: none;
          border-top: none;
          top: 0;
          right: 0;
          bottom: calc(30px / 8);
          left: 0;
          transform: rotate(-45deg);
          box-sizing: content-box;
        }
      }

      // stylelint-disable-next-line max-nesting-depth
      &::before {
        transition: border-color 0s cubic-bezier(0.25, 0.1, 0.25, 1);
        position: absolute;
        content: '';
        margin: auto;
        width: calc(22px / 3.5);
        height: calc(22px / 3.5);
        background: rgb(0 0 0 / 0%);
        border: calc(22px / 10) solid #fff;
        border-right: none;
        border-top: none;
        top: 0;
        right: 0;
        bottom: calc(30px / 8);
        left: 0;
        transform: rotate(-45deg);
        box-sizing: content-box;
      }
    }
  }

  .dropdown {
    visibility: visible;
    opacity: 1;
  }

  .dropdownHidden {
    visibility: hidden;
    opacity: 0;
  }

  // stylelint-disable-next-line a11y/content-property-no-static-value
  .hoverPointer {
    background-color: #fff;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    box-sizing: border-box;
    content: '';
    height: 18px;
    position: absolute;
    top: 77px;
    transform: rotate(45deg) skew(2deg, 2deg);
    width: 20px;
    z-index: 90;
    margin-left: 33px;
  }

  &:hover,
  &:focus {
    cursor: pointer;

    a {
      color: $istock-link-hover-color;
    }

    .caretContainer {
      top: 7px;

      .caret {
        // stylelint-disable-next-line max-nesting-depth
        &::before {
          border-color: $istock-link-hover-color;
          transform: rotate(135deg);
        }
      }
    }
  }
}

.isVector {
  display: none;

  @media #{$large-up-nav-only} {
    display: inline-block;
  }
}
