@import '../Header.config';

.link {
  padding: 0 3px 0 12px;

  &:hover,
  &:focus {
    cursor: pointer;

    a {
      color: $istock-link-hover-color;
    }
  }
}

// stylelint-disable-next-line selector-id-pattern
#pricingLink {
  color: #b4b9b9;

  &:hover,
  &:focus {
    cursor: pointer;
    color: $istock-link-hover-color;
  }
}
