@use 'sass:math';
@use '@unisporkal/alliance-ui-theme/dist/styles/iStock' as iStockTheme;
@import '../Header.config';

$notification-font-size: 0.875rem;
$notification-narrow-font-size: rem(12);
$notification-bg-color: $some-grey;
$notification-gutter: 15px;

.creditsSubsTable {
  margin: 0;
  padding: 0;
  border-collapse: unset;

  tr {
    background-color: unset;
    border: unset;
  }

  td {
    padding: 0 !important;
    vertical-align: middle;
  }
}

.subscriptionAlert,
.agreementAlert {
  background: $notification-bg-color;
  padding: $notification-gutter;
  margin-bottom: $notification-gutter;
  display: flex;
  flex: 0 1 auto;

  figure {
    background-image: url('~unisporkal-styles/stylesheets/unisporkal_engine/images/exclamation-mark.svg');
    background-repeat: no-repeat;
    background-size: 50px;
    background-position: -15px;
    width: 20px;
    height: 40px;
    margin: 0 10px 0 0;
  }

  p {
    font-size: $notification-font-size;
    margin: 0;
    line-height: 1.5;
    flex: 1;
    color: $dark-grey !important;
  }

  a {
    color: $href_color !important;

    // stylelint-disable-next-line a11y/content-property-no-static-value
    &::before {
      content: ' ';
    }

    &:hover,
    &:focus,
    &:active {
      color: $href_hover_color !important;
    }

    &.serverAlertLink {
      font-size: $notification-font-size;
    }

    &.noWrapCta {
      white-space: nowrap;
    }

    &.agreementLink {
      display: block;
    }
  }

  &.zeroDownloadRenew {
    display: none;
  }
}

.remaining {
  .count {
    font-size: rem(28);
    margin: 0;
    color: $dark-grey;
  }

  p {
    font-size: $notification-narrow-font-size;
    color: $shale-grey;
    margin: 0 0 0 10px;
    width: 100%;
  }
}

.onboardingVideoPanel {
  font-size: $notification-narrow-font-size;
  background-color: $notification-bg-color;
  padding: $notification-gutter;
  margin-top: math.div($notification-gutter, 2);

  p {
    font-size: 1em;
    margin: 0 0 8px;
    line-height: 1.5;
    color: $dark-grey;
  }

  button {
    color: $href_color !important;
    font-size: 1em;
    text-align: left;

    // stylelint-disable-next-line a11y/content-property-no-static-value
    &::after {
      content: ' >';
    }

    &:hover,
    &:focus,
    &:active {
      color: $href_hover_color !important;
    }
  }
}

.horizontalDivider {
  border: 1px solid #ccd3d3;
  border-bottom-color: #fff;
  display: block;
  margin: 15px 0 25px;
}

.checkmark {
  fill: iStockTheme.$secondary;
}
