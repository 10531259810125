@import '../Header.config';

.container {
  display: none;

  @media #{$medium-large-nav} {
    display: flex;
    align-items: center;
    margin: 0;
  }
}

.divider {
  border-right: 1px solid #666e6e;
  height: 24px;
  margin: 0 5px -8px 15px;
}

.generator {
  padding: 0 3px 0 12px;
}

.largeScreen {
  display: none;

  @media #{$large-up-nav-only} {
    display: inline-block;
  }
}

.mediumScreen {
  display: none;

  @media #{$medium-large-nav} {
    display: inline-block;
  }

  @media #{$large-up-nav-only} {
    display: none;
  }
}
