@import './Header.config';

.container {
  background-color: $istock-header-bg-color;

  // reseting all the default styles
  button {
    text-transform: none;
    border: 0;
    padding: 0;
    background: transparent;
    font-size: 100%;
    font-weight: 500;
  }

  button,
  a {
    color: $istock-text-color;
    font-weight: 400;

    &:hover,
    &:focus {
      color: $istock-link-hover-color;
    }
  }
}
