@import '../Header.config';

.link {
  padding: 0;
  color: black !important;
  font-size: 14px;

  &:hover,
  &:focus {
    color: $istock-link-hover-color !important;
  }
}

.linkList {
  margin-top: 0;
  margin-bottom: 10px;
  max-width: 320px;
}

.resourcesLinks {
  padding: 0 !important;
  color: black !important;
  line-height: 24px;
  font-weight: 400;

  &:hover,
  &:focus {
    color: $istock-link-hover-color !important;
  }
}
