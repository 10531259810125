@import '../Header.config';

$items-line-height: 31px;

.container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #c5d2d2;
  border-radius: 8px;
  width: 100%;
  padding: 15px 24px 10px;
  margin-top: 15px;
  line-height: 1.4;
  list-style: none;

  .listItem {
    margin-left: -16px;
    padding: 0 24px;
    line-height: $items-line-height;
    color: $istock-text-dark-grey;

    &:hover,
    &:focus {
      color: $istock-link-hover-color;
    }
  }
}
