@import '../Header.config';

.container {
  background-color: $istock-white;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 32px;
  margin: 0;
  z-index: 111;

  .link {
    padding: 0;
    color: $istock-text-dark-grey !important;
    font-size: 14px;

    &:hover,
    &:focus {
      color: $istock-link-hover-color !important;
    }
  }

  .linkList {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .seeMoreLink {
    cursor: pointer;
    text-align: left;
    color: $istock-link-hover-color !important;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .active {
    visibility: visible;
    opacity: 1;
    transition: none;
  }
}

.active {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s ease-in-out 0.1s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .notActive {
    visibility: hidden;
    opacity: 0;
    transition: none;
  }
}

.notActive {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s ease-in-out 0.1s;
}
