.container {
  background-color: white;
  position: absolute;
  margin-top: 30px;
  left: 0;
  width: 100%;
  z-index: 111;
  height: 380px;
  cursor: default;

  .closeButton {
    background-image: url('./../icons/close.svg');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 20px;
    right: 50px;
    height: 15px;
    width: 15px;
    cursor: pointer;
    z-index: 122;
  }
}
