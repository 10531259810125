@import '../Header.config';

$first-child-color: #666e6e;
$items-line-height: 31px;

.container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #c5d2d2;
  border-radius: 2px;
  width: 100%;
  padding: 0 24px 10px;
  margin-top: 20px;
  line-height: 1.4;
  list-style-position: none;

  .textMessage {
    margin: 0 auto;
    padding: 1rem 0;
    line-height: normal;
    color: $istock-text-dark-grey;
  }

  .headline {
    margin: 0;
    padding: 0;
    color: $first-child-color;
    font-size: 12px;
    font-weight: 100;
    line-height: 40px;
    text-transform: uppercase;
    cursor: text;
  }

  .rectangle {
    height: 1px;
    border: 0.25px solid #adb9ba;
    margin: 7px auto 8px;
    width: 221px;
    display: block;
    background: transparent;
  }

  .listItem {
    margin: 0 -24px;
    padding: 0 24px;
    line-height: $items-line-height;
    color: $istock-text-dark-grey;

    .button {
      color: $istock-link-hover-color;
      line-height: $items-line-height;
    }

    .label {
      opacity: 0;
      color: #fff;
      background-color: $istock-link-hover-color;
      border-radius: 500px;
      padding: 0 0.5rem;
      font-size: 0.7rem; // 11px
      font-weight: 100;
      line-height: 14px;
    }

    &:hover,
    &:focus {
      background-color: $istock-hover-selection-color;

      .label {
        opacity: 1;
      }

      .collabIcon {
        opacity: 0;
      }
    }

    .link {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .boardName {
      color: $istock-text-dark-grey;
      font-weight: 700;
      float: left;
      padding: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
