@import '../Header.config';

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin: 48px 0;

  @media #{$large-up-nav-only} {
    margin: 64px 0;
  }

  .header {
    margin-right: 16px;

    @media #{$large-up-nav-only} {
      margin-right: 32px;
    }

    .title {
      font-size: 1.2em;
      font-weight: 400;
      line-height: 1.4;
      margin-bottom: 0;
      margin-right: 10px;
      color: black;
    }
  }

  .backLink {
    cursor: pointer;
    font-size: 0.85rem;
    color: #02a388 !important;
  }
}

.categories {
  column-count: 3;
  border-left: 1.5px solid #ccd3d3;
  position: relative;
  padding-left: 2rem;

  @media #{$large-up-nav-only} {
    column-count: 4;
  }
}

.seeMoreListItem {
  width: 16rem;
  margin-bottom: 18px;
}

.seeMoreItemLink {
  color: black !important;
  font-size: 14px;

  &:hover,
  &:focus {
    color: #02a388 !important;
  }
}
