@import '../../getty/Header.config';
@import '../../istock/Header.config';

@media screen and (prefers-reduced-motion: reduce) {
  .istockContainer {
    opacity: 1;
    transition: none;
    background-color: $school-bus-yellow;
    border-top: 1px solid $grey;
    box-shadow: 0 5px 8px rgba($dark-grey, 0.25);
    color: $dark-grey;
    display: flex;
    margin-bottom: 4px;
    padding: 16px 0;
    text-align: center;
  }
}

.istockContainer {
  opacity: 1;
  transition: all 0.25s ease-in-out;
  background-color: $school-bus-yellow;
  border-top: 1px solid $grey;
  box-shadow: 0 5px 8px rgba($dark-grey, 0.25);
  color: $dark-grey;
  display: flex;
  margin-bottom: 4px;
  padding: 16px 0;
  text-align: center;
}

@media screen and (prefers-reduced-motion: reduce) {
  .gettyContainer {
    opacity: 1;
    transition: none;
    background-color: $purple;
    border-bottom: 1px solid $purple-darker;
    border-top: 1px solid $banner-border-lighten;
    color: $true-white;
    display: flex;
    margin-bottom: 4px;
    padding: 16px 0;
    text-align: center;
  }
}

.gettyContainer {
  opacity: 1;
  transition: all 0.25s ease-in-out;
  background-color: $purple;
  border-bottom: 1px solid $purple-darker;
  border-top: 1px solid $banner-border-lighten;
  color: $true-white;
  display: flex;
  margin-bottom: 4px;
  padding: 16px 0;
  text-align: center;
}

.banner {
  flex-grow: 100;
  margin: 0 14px;
  font-size: 1rem;
  width: 100%;
}
