@import 'unisporkal-styles/stylesheets/unisporkal_engine/istock/_config';
@import 'unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints';
@import 'unisporkal-styles/stylesheets/unisporkal/shared/config/_functions';

// Update to generics if getty is same color
// Below text-color should not be used for all anchor tags
$istock-text-color: $white;
$istock-white: $white;
$istock-header-bg-color: $dark-grey;
$istock-header-grey: $grey;
$istock-text-dark-grey: $dark-grey;
$istock-header-category-color: hsl(0deg 0% 100% / 60%);
$istock-header-link-color: hsl(0deg 0% 100% / 80%);
$istock-border-color: #c5d2d2;
$istock-link-hover-color: $green;
$istock-hover-selection-color: #ebf1f5;
$wide-header-height: 85px;
$thin-header-height: 65px;
$panel-width: 300px;
$narrow-panel-width: 250px;
$istock-mobile-bg-color: $dark-grey-metallic;

// Breakpoint 1440px+
$large-lower-nav: 1440px;
$large-up-nav-only: 'screen and (min-width: #{$large-lower-nav})' !default; /* ~ desktops */

// Breakpoint 1024px - 1439px
$medium-large: 1024px;
$medium-large-nav: 'screen and (min-width: #{$medium-large})' !default; /* ~ desktops */

// Breakpoint  768px - 1023px
$medium: 768px;
$medium-nav: 'screen and (min-width: #{$medium})' !default; /* ~ tablets */

// Breakpoint 320px - 767px
$small: 320px;
$small-nav: 'screen and (min-width: #{$small})' !default; /* ~ mobile */

// Breakpoint for MobileNav width (800px)
$tablet: 'screen and (max-width: #{$max_tablet_width})';
$lower-mobile: 540px;
$mobile-lower: 'screen and (max-width: #{$lower-mobile})';

// Breakpoint 540px and up
$mobile-lower-up-nav: 'screen and (min-width: #{$lower-mobile})';
$upper-mobile: 640px;
$mobile-upper: 'screen and (max-width: #{$upper-mobile})';
