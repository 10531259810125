@import '../Header.config';

.container {
  cursor: pointer;
  margin: 0 10px 0 -15px;
  display: flex;
  align-items: center;
  width: 41px;
  height: 40px;
  background-color: transparent;

  .icon {
    &:hover path,
    &:focus path {
      fill: #02a388;
    }
  }

  @media #{$medium-large-nav} {
    display: none;
  }
}
