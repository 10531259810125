@use '@unisporkal/alliance-ui-theme/dist/styles/iStock' as iStockTheme;

// Use high specificity to override the hover style set by Header component.
.emphasizedSignIn.container {
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid iStockTheme.$secondary-dark;
  background: iStockTheme.$secondary;
  margin-left: 20px;

  &:hover,
  &:focus {
    color: iStockTheme.$true-white;
    background: iStockTheme.$secondary-dark;
  }
}
