@import '../Header.config';

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: $thin-header-height;

  @media #{$medium-large-nav} {
    height: $wide-header-height;
  }

  @media #{$large-up-nav-only} {
    position: relative;
  }
}
