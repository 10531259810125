@import '../Header.config';

@media screen and (prefers-reduced-motion: reduce) {
  .container {
    width: $panel-width;
    background-color: $istock-white;
    color: $istock-text-dark-grey;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-$panel-width);
    transition: none;
    transition-delay: 0s;

    @media #{$tablet} {
      width: $narrow-panel-width;
      transform: translateX(-$narrow-panel-width);
    }
  }
}

.container {
  width: $panel-width;
  background-color: $istock-white;
  color: $istock-text-dark-grey;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-$panel-width);
  transition: transform 0.25s;
  transition-delay: 0s;

  @media #{$tablet} {
    width: $narrow-panel-width;
    transform: translateX(-$narrow-panel-width);
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .openMenu {
    width: $panel-width;
    background-color: $istock-white;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    transition: none;
    transition-delay: 0s;

    // This is to prevent pseduo-elements
    // (carets) to overlap this
    z-index: 1;

    @media #{$tablet} {
      width: $narrow-panel-width;
    }
  }
}

.openMenu {
  width: $panel-width;
  background-color: $istock-white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.25s;
  transition-delay: 0s;

  // This is to prevent pseduo-elements
  // (carets) to overlap this
  z-index: 1;

  @media #{$tablet} {
    width: $narrow-panel-width;
  }
}

.mobileMediaNav {
  position: relative;
  margin: 0;
  padding: 0 30px 0 40px;
}

.backSection {
  height: $thin-header-height;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 26px;
  padding-left: 40px;
  display: flex;
  align-items: center;

  @media #{$medium-large-nav} {
    height: $wide-header-height;
  }

  // stylelint-disable-next-line a11y/content-property-no-static-value
  .caret {
    content: '<';
    position: absolute;
    left: 40px;
    line-height: 16px;
    font-size: 21px;
    z-index: -1;
  }

  .backButton {
    font-weight: 400;
    color: $istock-text-dark-grey;
    padding-left: 27px;

    &::before {
      // stylelint-disable-next-line scss/at-extend-no-missing-placeholder
      @extend .caret;
    }
  }
}
