@import '../Header.config';

$notification-font-size: 0.875rem;
$notification-narrow-font-size: rem(12);

@mixin apply-mask-image($image-url) {
  mask-image: url($image-url);
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
}

$icon-withspace: 8px;

.panelOpen {
  transform: translateX(-$panel-width);
  overflow-x: hidden;

  @media #{$tablet} {
    transform: translateX(-$narrow-panel-width);
  }
}

.listLink {
  display: flex;
  align-items: baseline;
}

.menuIcon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-repeat: no-repeat;
  position: relative;
  top: 6px;
  margin-right: 10px;
}

.headerSidePanel {
  .boardsListItem {
    @media #{$medium-large-nav} {
      display: none !important;
    }
  }

  li {
    padding: 0 0 8px;
    min-width: 100px;

    a {
      color: $dark-grey !important;
    }

    .menuIcon {
      background-color: $dark-grey;
    }

    .overview {
      @include apply-mask-image(
        $image-url:
          '~unisporkal-styles/stylesheets/unisporkal_engine/images/overview.svg'
      );
    }

    .downloadHistory {
      @include apply-mask-image(
        $image-url:
          '~unisporkal-styles/stylesheets/unisporkal_engine/images/download_history.svg'
      );
    }

    .generationHistory {
      @include apply-mask-image(
        $image-url:
          '~unisporkal-styles/stylesheets/unisporkal_engine/images/generation-history.svg'
      );
    }

    .accountDetails {
      @include apply-mask-image(
        $image-url:
          '~unisporkal-styles/stylesheets/unisporkal_engine/images/account_details.svg'
      );

      top: $icon-withspace;
    }

    .videoTemplates {
      mask-image: url('~unisporkal-styles/stylesheets/unisporkal_engine/images/video_templates.svg');
    }

    .imageProjects {
      @include apply-mask-image(
        $image-url:
          '~unisporkal-styles/stylesheets/unisporkal_engine/images/image_projects.svg'
      );

      top: $icon-withspace;
    }

    .videoProjects {
      mask-image: url('~unisporkal-styles/stylesheets/unisporkal_engine/images/video_projects.svg');
    }

    .boards {
      mask-image: url('~unisporkal-styles/stylesheets/unisporkal_engine/images/boards.svg');
      // stylelint-disable-next-line max-nesting-depth
      @media #{$medium-large-nav} {
        display: none;
      }
    }
  }

  li:hover,
  li:focus {
    a {
      color: rgb(0 168 145) !important;
    }

    .menuIcon {
      background-color: rgb(0 168 145);
    }
  }

  h4 {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 16px;
    text-transform: uppercase; // TODO: until we can get new ilox (legacy uses transform)
  }

  .yourAccount {
    margin-bottom: 6px;
  }

  .remaining {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-start;
    align-items: center;

    .count {
      font-size: rem(28);
      min-width: 60px;
      margin: 0;
      color: #0c0d0d;
    }

    p {
      font-size: $notification-narrow-font-size;
      color: $shale-grey;
      margin: 0;
      width: 100%;
    }
  }

  .emailNavbar {
    font-family: 'iStock Maquette', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $shale-grey;
    margin-bottom: 24px;
    word-wrap: break-word;
  }
}

.mobileMenu {
  // TODO: still have to figure it out how to remove these important
  margin-right: 0 !important;
  color: $dark-grey !important;
}

.horizontalDivider {
  border: 1px solid #ccd3d3;
  border-bottom-color: #fff;
  display: block;
  margin: 15px 0 25px;
}
