@import '../Header.config';

$items-line-height: 31px;

.container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #c5d2d2;
  border-radius: 8px;
  line-height: 1.4;
  list-style: none;
  margin-top: 15px;
  padding: 8px 0;

  .links {
    li {
      margin: 0;
      padding: 0 20px;

      &:hover,
      &:focus {
        background-color: $istock-hover-selection-color;
      }
    }
  }
}
