@import '../Header.config';

.mediaAndCaret {
  padding-right: 17% !important;
  text-align: left;
}

// stylelint-disable-next-line a11y/content-property-no-static-value
.mediaAndCaret::after {
  content: '>';
  position: absolute;
  padding: 0 10% !important;
  right: 0;
  line-height: 19px;
  font-size: 21px;
}
