@import '../Header.config';

.wrapper {
  position: relative;
}

.container {
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    &:hover path,
    &:focus path {
      fill: $istock-link-hover-color;
    }
  }

  .name {
    padding-left: 10px;
    display: none;

    @media #{$large-up-nav-only} {
      display: block;
    }
  }

  &:hover,
  &:focus {
    cursor: pointer;

    svg {
      path {
        fill: $istock-link-hover-color;
      }
    }

    .name {
      color: $istock-link-hover-color;
    }
  }
}

.notificationDot {
  background-color: $red;
  border-radius: 50%;
  float: left;
  height: 8px;
  width: 8px;
  position: relative;
  right: 8px;
  top: 5px;

  @media #{$mobile-lower} {
    right: -3px;
    top: -3px;
    width: 6px;
    height: 6px;
  }
}
