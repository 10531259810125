@import '../Header.config';

.container {
  position: relative;

  @media #{$mobile-lower} {
    display: none;
    visibility: hidden;
  }

  .link {
    padding: 0 3px 0 12px;

    @media #{$mobile-upper} {
      display: none;
    }
  }

  @media screen and (prefers-reduced-motion: reduce) {
    .icon {
      position: relative;
      top: 2px;
      transition: none;
      height: 14px;
      width: 14px;
    }
  }

  .icon {
    position: relative;
    top: 2px;
    transition: background-image 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
    height: 14px;
    width: 14px;
  }

  .dropdown {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    width: 265px;
    right: 0;

    // Copied from engine, still will work to improve
    transition-duration: 0.15s;
    transition-property: all;
    transition-timing-function: ease;
    z-index: 999;
  }

  &:hover,
  &:focus {
    cursor: pointer;

    .icon {
      g {
        fill: $istock-link-hover-color;
      }
    }

    .link {
      color: $istock-link-hover-color;
    }

    .dropdown {
      visibility: visible;
      opacity: 1;
      transition-duration: 0.15s;
      transition-property: all;
      transition-timing-function: ease;
    }

    .caretContainer {
      top: 7px;

      .caret {
        // stylelint-disable-next-line max-nesting-depth
        &::before {
          border-color: $istock-link-hover-color;
          transform: rotate(135deg);
        }
      }
    }
  }
}
