@import '../Header.config';

@media screen and (prefers-reduced-motion: reduce) {
  .closeMenu {
    width: $panel-width;
    background-color: $istock-white;
    color: $istock-text-dark-grey;
    position: fixed;
    margin-top: 0;
    top: 0;
    bottom: 0;
    right: 0;
    transform: translateX($panel-width);
    transition: none;
    transition-delay: 0s;

    @media #{$tablet} {
      width: $narrow-panel-width;
      transform: translateX($narrow-panel-width);
    }
  }
}

.closeMenu {
  width: $panel-width;
  background-color: $istock-white;
  color: $istock-text-dark-grey;
  position: fixed;
  margin-top: 0;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX($panel-width);
  transition: transform 0.25s;
  transition-delay: 0s;

  @media #{$tablet} {
    width: $narrow-panel-width;
    transform: translateX($narrow-panel-width);
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .openMenu {
    width: $panel-width;
    background-color: $istock-white;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    transition: none;
    transition-delay: 0s;
    transform: translateX($panel-width);
    border-left: 1px solid $extra-light-grey;
    padding: 3rem 2.25rem;

    @media #{$tablet} {
      transform: translateX($narrow-panel-width);
      width: $narrow-panel-width;
    }

    .closeButton {
      background-image: url('./../icons/close.svg');
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 20px;
      right: 20px;
      height: 15px;
      width: 15px;
      cursor: pointer;
      z-index: 9999;
    }
  }
}

.openMenu {
  width: $panel-width;
  background-color: $istock-white;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transition: transform 0.25s;
  transition-delay: 0s;
  transform: translateX($panel-width);
  border-left: 1px solid $extra-light-grey;
  padding: 3rem 2.25rem;

  @media #{$tablet} {
    transform: translateX($narrow-panel-width);
    width: $narrow-panel-width;
  }

  .closeButton {
    background-image: url('./../icons/close.svg');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 20px;
    right: 20px;
    height: 15px;
    width: 15px;
    cursor: pointer;
    z-index: 9999;
  }
}
