@import '../Header.config';

.container {
  align-items: center;
  margin-left: auto;
  line-height: unset;
  justify-content: center;
  display: flex;
  font-size: 14px;

  li {
    display: inline-block !important;
    padding: 0 3px 0 12px;
  }
}

.navList {
  display: flex;
  align-items: center;
  margin-right: 28px;
  margin-bottom: 0; // explicit to override if existing

  @media #{$mobile-lower} {
    margin-right: 11px;
  }
}

.wrapper {
  @media #{$mobile-lower} {
    display: none;
  }

  li:last-child {
    display: none !important;

    @media #{$medium-large-nav} {
      display: inline-block !important;
    }
  }
}
