@import '../Header.config';

.desktop {
  display: none;
  margin-top: 5px;
  margin-right: 12px;
  width: 80px;
  height: 34.26px;

  @media #{$medium-large-nav} {
    display: inline-flex;
  }
}

.mobile {
  display: inline-flex;
  margin-top: 5px;
  height: 21.33px;
  width: 80px;

  @media #{$medium-large-nav} {
    display: none;
  }
}
