@import '../Header.config';

.container {
  display: none;

  @media #{$large-up-nav-only} {
    display: inline;
  }
}

.mobile {
  display: inline-block;

  @media #{$large-up-nav-only} {
    display: none;
  }
}

.panelOpen {
  transform: translateX(-$panel-width);
  overflow-x: hidden;

  @media #{$tablet} {
    transform: translateX(-$narrow-panel-width);
  }
}

.mobileMenu {
  // still have to figure it out how to remove these important
  margin-right: 0 !important;

  li {
    margin: 0 !important;
    border-bottom: 1px solid $extra-light-grey !important;
    width: 100%;
    display: flex !important;
  }

  a {
    padding: 19px 12px 19px 30px !important;
    display: block !important;
    color: $dark-grey !important;
  }

  li:nth-last-child(-n + 2) {
    display: none;
    visibility: hidden;

    @media #{$mobile-lower} {
      visibility: visible;
    }
  }
}
